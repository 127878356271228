.invitation-container {
  width: 100%;
  height: 100%;
}
.normal-nav {
  display: flex;
  width: 100%;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(204, 206, 228, 0.5);
}
.back-icon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}
.normal-nav-title {
  color: #121410;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.invitation-content {
  width: 100%;
  overflow-y: auto;
}
.content-top {
  box-sizing: border-box;
  width: 100%;
  height: 23rem;
  background-image: url('./assets/bg1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.content-mid {
  width: 100%;
  height: 23rem;
  background-image: url('./assets/bg2.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 23rem;
  left: 0;
}
.content-bottom {
  width: 100%;
  height: 16.4375rem;
  background-image: url('./assets/bg3.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 46rem;
  left: 0;
}
.real-content-top {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding-top: 20.625rem;
}
.code-wrap {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.875rem;
  position: relative;
}
.invitation-rule {
  position: absolute;
  top: -3.25rem;
  right: 0.875rem;
  display: flex;
  box-sizing: border-box;
  width: 4.875rem;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #006fff 0%, #00a6b1 100%);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.3) inset;
}
.invitation-rule-text {
  color: #fff;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.code-bg {
  width: 100%;
  height: 6.0625rem;
  background-image: url('./assets/code-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 2.25rem 1.4375rem 0 2.375rem;
}
.code-action-wrap {
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.code-text {
  width: 9.85rem;
  margin-left: -0.95rem;
  color: #fff;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-align: center;
}
.code-actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 0.25rem;
}
.copy-action {
  width: 5rem;
  height: 2.25rem;
  background-image: url('./assets/copy-btn.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.refresh-action {
  width: 2.25rem;
  height: 2.25rem;
  background-image: url('./assets/refresh-btn.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.invitation-tabs {
  margin-top: 0.75rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  background: linear-gradient(180deg, #2fe5c9 0%, #257995 100%);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.invitation-tab-active {
  display: flex;
  box-sizing: border-box;
  height: 2.75rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border-radius: 0.5rem 0.5rem 0 0;
  background: linear-gradient(180deg, #ffbf00 0%, #b14d00 100%);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.3) inset;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.invitation-tab-default {
  display: flex;
  box-sizing: border-box;
  height: 2.75rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border-radius: 0.5rem 0.5rem 0 0;
  background: linear-gradient(180deg, #008a95 0%, #00a9a6 100%);
  color: #eee;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.invitation-tab1-content {
    padding: 1rem 0 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 2%, #20b7d3 25%, #20B7D3);
    /* background: #20B7D3; */
}
.tab1-top {
  width: 100%;
  height: 34.875rem;
  background-image: url('./assets/1.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.tab2-top {
  width: 100%;
  height: 45.5rem;
  background-image: url('./assets/2.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.tab3-top {
  width: 100%;
  height: 46.25rem;
  background-image: url('./assets/3.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.tab4-top {
  margin: 0 0.875rem;
  width: calc(100% - 1.75rem);
  height: 2.8125rem;
  background-image: url('./assets/4.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.invitation-tab1-content .tc-top {
  padding: 0 0.875rem;
  color: var(--T1);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  word-break: break-word;
  display: flex;
}
